const mainNav = document.querySelector(`.main-nav--dropdown`);
if (mainNav) {
  const burger = mainNav.querySelector(`.logo__burger`);
  const mobileNavElements = mainNav.querySelectorAll(`.main-nav__item--mobile`);
  
  const openDropDown = () => {
    burger.classList.add(`logo__burger--close`);
    burger.removeEventListener(`click`, openDropDown);
    burger.addEventListener(`click`, closeDropDown);
    mobileNavElements.forEach((element) => {
      element.style.display = `flex`;
    });
  };
  
  const closeDropDown = () => {
    burger.classList.remove(`logo__burger--close`);
    burger.removeEventListener(`click`, closeDropDown);
    burger.addEventListener(`click`, openDropDown);
    mobileNavElements.forEach((element) => {
      element.style.display = `none`;
    });
  };
  
  burger && burger.addEventListener(`click`, openDropDown);

}
